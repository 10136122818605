import httpUtil from "@/utils/httpUtil";

 
/** 企微授权二维码 */
export const authUrl = async params => httpUtil.post("/api/crmPc/qywx/authUrl ", params)

/** 企微授权开关 */
export const qywxSetting = async params => httpUtil.post("/api/crmPc/qywx/qywxSetting ", params)


/** 企微客户数据 */
export const syncCustomer = async params => httpUtil.post("/api/crmPc/qywx/syncCustomer ", params)


export const callback = params => httpUtil.get("/api/crmPc/qywx/auth/callback", params);

/** 同步架构及员工信息 */
export const syncDepartment = async params => httpUtil.post("/api/crmPc/qywx/syncDepartment ", params)

/** 获取「联系客户统计」数据 */
export const behaviorData = async params => httpUtil.post("/api/crmPc/qywx/behaviorData ", params)

/** 获取「群聊数据统计」数据 */
export const groupChatStatistic = async params => httpUtil.post("/api/crmPc/qywx/groupChatStatistic ", params)

